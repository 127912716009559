<template>
  <div>
    <Preloader v-if="loading"/>
    <div class="widget">
      <b-form class='row'>
        <b-form-group label="От:" class="col-6" label-for="input-date_from">
          <b-form-datepicker id="input-date_from"
                             v-model="date_from"
                             :value="date_from"
                             :data-date-format="'mm.dd.yyyy'"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             locale="ru"
                             @input='getStats'
                             required>
          </b-form-datepicker>
        </b-form-group>

        <b-form-group label="По:" class="col-6 mb-5" label-for="input-date_to">
          <b-form-datepicker id="input-date_to"
                             v-model="date_to"
                             :value="date_to"
                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                             locale="ru"
                             @input='getStats'
                             required>
          </b-form-datepicker>
        </b-form-group>

      </b-form>
    </div>

    <div class="widget"
     v-if="stats && Object.keys(stats).length > 0">
      <table class='table table-hover'>
        <thead class='table_head'>
        <tr class="text-center">
          <th>Кол-во учеников</th>
          <th>Часов занятий</th>
          <th>Отзывов</th>
          <th>Запланированных</th>
          <th>Пробных</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in stats"
              :key="key"
              class="text-center font-weight-bold">
            <td>{{ item.all_students }}</td>
            <td>{{ item.all_lessons }}</td>
            <td>{{ item.reviews }}</td>
            <td>{{ item.status_planned_lessons }}</td>
            <td>{{ item.type_trial_lessons }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="widget" v-else>
      <p>Результатов не найдено</p>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import Preloader from "../../components/Preloader";
import {mapGetters} from "vuex";

export default {
  name: "TeacherStats",
  components: { Preloader },
  data() {
    return {
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
      stats: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getUserId : 'user/getUserId'
    })
  },
  methods: {
    async getStats() {
      this.loading = true;

      const from = moment(this.date_from).format('DD.MM.YYYY');
      const to = moment(this.date_to).format('DD.MM.YYYY');

      let params = {
        from,
        to
      }

      try {
        const { data } = await this.$axios.get(`/api/teacher/stats/${this.getUserId}`, { params });

        this.stats = data.data;

        this.loading = false;
      }catch (e) {
        this.loading = false;
        console.log(`Error get statistics teacher: ` . e)
      }
    },
  },
  async created() {
    await this.getStats()
  }
}
</script>

<style lang="scss">
</style>